import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/blog32.jpg'
import styles from './post-grid.module.css'

const CardioStrengthLoosingWeight = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Kardio nebo silový trénink. Co je lepší na hubnutí?'}
        description={
          'Není tajemstvím, že fyzická aktivita je velkým pomocníkem při dosažení ideální váhy. '
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/mikl-thistle">
            Kardio nebo silový trénink. Co je lepší na hubnutí?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="Kardio nebo silový trénink. Co je lepší na hubnutí?" date="12.09.23" />
            <div>
              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />

              <p className={styles.postText}>
                Není tajemstvím, že<b> fyzická aktivita </b>je
                <b> velkým pomocníkem </b>při dosažení ideální váhy. Nemluvě o
                zdravotních přínosech cvičení. Jaký typ tréninku je však
                <b> nejlepší zvolit</b>? Pomůže vám s hubnutím spíše kardio nebo
                silový trénink? Odpoveď vás možná překvapí.
              </p>
              <h5 className={styles.postTitle}>Kardio cvičení</h5>

              <p className={styles.postText}>
                Kardio cvičení je založeno na aktivitě, která
                <b> zvyšuje tepovou frekvenci a pomáhá spalovat kalorie</b>. Mezi
                nejoblíbenější kardio cvičení patří běhání, cyklistika, chůze,
                plavání a skákání přes švihadlo. Tyto aktivity mohou být velmi
                <b> účinné pro spalování </b>a také pomáhají
                <b> k udržení správné funkce kardiovaskulárního systému.</b>
              </p>
              <h5 className={styles.postTitle}>Silový trénink</h5>
              <p className={styles.postText}>
                Na druhé straně silový trénink se zaměřuje
                <b> na budování a udržení svalové hmoty.</b> Může být proveden s
                vlastní vahou nebo s pomocí posilovacích strojů či závaží. Cílem
                silového tréninku je tedy
                <b> zvýšit nebo udržet svalovou hmotu,</b> což má, mimo jiné,
                <b> pozitivní vliv na rychlost metabolismu. I</b> při silovém
                tréninku samozřejmě <b>spalujete kalorie</b>, jen je to znatelně
                <b> méně, než při kardiu. </b>Nicméně díky silovému tréninku
                <b> zvyšujete kalorický výdej i po tréninku, </b>a to cca o 5 %
                následující den až dva.
              </p>

              <p className={styles.postText}>
                <b><i>TIP</i></b><i>: Chcete nabrat svaly? </i>{' '}
                <a
                  href="https://nutritionpro.cz/blog/potrebujete-nabrat-svaly-krabickova-strava-resenim/"
                  target="_blank"
                >
                  <i>Krabičková dieta je řešením!</i>
                </a>
              </p>
              <h5 className={styles.postTitle}>Kardio nebo silový trénink?</h5>
              <p className={styles.postText}>
                Pokud budete hubnout pouze
                <b> prostřednictvím diety a kardia, </b>budete skutečně ubývat
                na váze,
                <b> vaše postava se ale nebude zpevňovat a tvarovat. </b>Vaše
                tělo bude při takovém hubnutí pravděpodobně
                <b> ztrácet i svalovou hmotu</b>, samotné kardio není totiž pro
                budování či udržení svalové hmoty dostatečné. A vy tak budete
                <b> štíhlí, ale “rozkydlí”. </b> Díky silovému tréninku získáte
                <b> pevnou a vytvarovanou postavu. </b>Výzkumy také ukazují, že
                lidé cvičící silový trénink si<b> lépe udrží výsledky </b>a
                nedochází u nich k jojo efektu.
              </p>
              <h5 className={styles.postTitle}>Jak tedy hubnout efektivně?</h5>

              <p className={styles.postText}>
                V první řadě se musíte<b> zaměřit na svůj jídelníček. </b>V tom
                vám mohou pomoci{' '}
                <a href="https://nutritionpro.cz/" target="_blank">
                  naše krabičky.{' '}
                </a>
                Dále je vhodné zařadit<b> silový trénink</b>, ideálně 3x týdně.
                Pokud chcete váš<b> kalorický výdej ještě více podpořit, </b>
                zařaďte<b> i kardio tréninky, </b> a to je 1-3x týdně. Vyberte
                si cvičení,<b> které vám vyhovuje </b>nejvíce a zaměřte se na
                správnou formu a intenzitu tréninku. Pokud nemáte rádi běh,
                zkuste jiné formy kardio cvičení, jako je například plavání nebo
                jízda na kole.
                <b>
                  {' '}
                  A pokud si nejste jisti, jak začít, poraďte se s trenérem.{' '}
                </b>
              </p>

              <p className={styles.postText}>
                <b><i>TIP</i></b>: <i>Podívejte se na </i>{' '}
                <a
                  href="https://nutritionpro.cz/blog/5-tipu-jak-hubnout-i-bez-diet/"
                  target="_blank"
                >
                  <i>5 tipů, jak hubnout i bez diet</i>
                </a>
              </p>
              <p className={styles.postText}>
                Hlavně nezapomeňte, že<b> méně je někdy více</b>, to znamená, že
                nemusíte hodiny denně trávit na kole nebo během, kardio cvičení
                pak nemusí být efektivní. Stejně tak
                <b>
                  {' '}
                  není důležité, jestli ve fitku spálíte 200 nebo 300 kalorií,{' '}
                </b>
                to totiž není cílem silového tréninku. Neberte jej jako nástroj
                na spalování kalorií, ale jako
                <b>
                  {' '}
                  nástroj, který vám pomůže vybudovat zdravé, silné a hezké
                  tělo.
                </b>
              </p>
              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default CardioStrengthLoosingWeight
